import { useClientLayoutEffect } from '@/src/hooks/useClientLayoutEffect';
import React from 'react';

export const useIsAnyRadixModalOpenCount = () => {
  const [radixModalsCount, setRadixModalsCount] = React.useState(0);

  useClientLayoutEffect(() => {
    // Options for the observer (which mutations to observe)
    const config = { childList: true, subtree: false };

    // Callback function to execute when mutations are observed
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver((mutationList) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          setRadixModalsCount(
            document.querySelectorAll(`[role="dialog"][data-state="open"]`).length,
          );
        }
      }
    });

    // Start observing the target node for configured mutations
    observer.observe(document.body, config);

    // Later, you can stop observing
    () => {
      observer.disconnect();
    };
  }, []);

  return radixModalsCount;
};

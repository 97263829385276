import { mediaMobile } from '@/src/modules/ui/styled-utils';
import styled from 'styled-components';

const TextAreaContainer = styled.div`
  width: 100%;
  background: var(--fabric-color-bg-secondary);
  border: 1px solid var(--fabric-color-border-primary);
  border-radius: var(--fabric-radius-input);
  transition:
    box-shadow 0.2s ease,
    border-color 0.2s ease;
  will-change: box-shadow, border-color;

  &:focus-within {
    outline: none;
    border-color: var(--fabric-color-app-primary);
  }

  ${mediaMobile} {
    background: var(--fabric-color-bg-secondary-button);
    border: none;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &:focus-within {
      border: none;
    }
  }
`;

const TextAreaInput = styled.textarea`
  padding: 13px;
  width: 100%;
  background: transparent;
  border: none;
  color: var(--fabric-color-text-primary);
  flex-grow: 1;
  min-height: 120px;
  font-weight: 400;
  overflow-y: auto;
  overflow-x: hidden;
  resize: none;

  &::placeholder {
    color: var(--fabric-color-text-placeholder) !important;
  }

  &:focus {
    outline: none;
  }

  ${mediaMobile} {
    min-height: 3rem;
  }
`;

export { TextAreaContainer, TextAreaInput };

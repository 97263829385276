import { pick } from '@/src/lib/store';
import { Header } from '@/src/modules/resources/components/NewResource/components/Header';
import useUIStore from '@/src/store/ui';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { shallow } from 'zustand/shallow';

import { EditorJSData } from '@/src/components/Tiptap/editorjs.types';
import Tiptap from '@/src/components/Tiptap/Tiptap';
import { NotepadPreviewWithBlocks } from '@/src/components/Tiptap/types';
import { getEmptyYdocBinaryState } from '@/src/components/Tiptap/utils/getEmptyYdocBinaryState';
import { useUnmount } from '@/src/hooks/useUnmount';
import { MagicSuggestions } from '@/src/modules/magic/components/MagicSuggestions';
import { MagicSuggestionsContextProvider } from '@/src/modules/magic/components/MagicSuggestionsContext';
import { SuggestionContext } from '@/src/modules/magic/magic.types';
import { SubmitFooter } from '@/src/modules/resources/components/NewResource/components/SubmitFooter';
import { useNewResourceContext } from '@/src/modules/resources/components/NewResource/context/ModalNewResourceContext';
import { useMutationCreateResourceV2 } from '@/src/modules/resources/mutations/useMutationCreateResourceV2';
import { Flex } from '@/src/modules/ui/components/Flex';
import { TextInput } from '@/src/modules/ui/components/TextInput/TextInput';
import { mediaMobile } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { prettifyForLLM } from '@/src/utils/text';
import { Editor } from '@tiptap/react';
import styled from 'styled-components';
import * as yup from 'yup';

const MAX_COMMENT_LENGTH = 25000;

const validationSchema = yup.object({
  title: yup.string().max(500, 'Title is too long'),
  comment: yup.string().max(MAX_COMMENT_LENGTH, 'Comment is too long'),
});

export const ModalContentNoteMobile: React.FC = () => {
  const { newModalOptions } = useUIStore((s) => pick(s, ['newModalOptions']), shallow);

  const { dataTransfer, text } = newModalOptions;

  const pastedContent = React.useMemo(() => {
    return dataTransfer ?? text;
  }, [dataTransfer, text]);

  const { destinationParent, selectedTags, handleClose, onSelectSuggestion } =
    useNewResourceContext();

  const { mutate: mutateCreateResource, isPending, isSuccess } = useMutationCreateResourceV2();

  const [editor, setEditor] = useState<Editor | null>(null);
  const [editorMarkdown, setEditorMarkdown] = useState<string | null>(null);
  const editorJsDataRef = React.useRef<NotepadPreviewWithBlocks | undefined>(undefined);
  const editorStateRef = React.useRef<Uint8Array | undefined>(undefined);

  const onFabricEditorChange = React.useCallback(
    (data: NotepadPreviewWithBlocks, binary: Uint8Array) => {
      editorJsDataRef.current = data;

      setEditorMarkdown(data.blocks.map((block) => block.data.text).join('\n'));
      editorStateRef.current = binary;
    },
    [],
  );

  const handleSave = async ({ title, comment }: { title: string; comment?: string }) => {
    if (isPending || isSuccess) {
      return;
    }

    if (!editorJsDataRef.current || !editor) {
      return;
    }
    const editorjs = editorJsDataRef.current as EditorJSData;
    const isTextEmpty =
      editorjs?.blocks.every((block) => block.type === 'paragraph' && block.data.text === '') ??
      true;

    const isMissingEditorData = !editor || editor.isEmpty || isTextEmpty;

    if (isMissingEditorData && title === '') {
      return;
    }

    return mutateCreateResource({
      data: {
        createType: 'note',
        body: {
          name: title || null,
          parentId: destinationParent!.id,
          ydoc: Array.from(editorStateRef.current || getEmptyYdocBinaryState()),
          tags: selectedTags.map((tag) => (tag.isDraft ? { name: tag.name } : { id: tag.id })),
          comment: comment
            ? {
                content: comment,
              }
            : undefined,
        },
        optimisticData: {
          tags: selectedTags,
          parent: destinationParent!,
          preview: {
            content: editorMarkdown || '',
          },
        },
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      title: '',
      comment: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async ({ title, comment }, formik) => {
      handleClose(
        handleSave({
          title,
          comment,
        }).finally(() => formik.setSubmitting(false)),
      );
    },
  });

  /**
   * @REF [save on unmount]
   */
  useUnmount(() => (!formik.isSubmitting ? formik.submitForm() : undefined), true);

  const suggestionsContext: SuggestionContext | undefined =
    !!formik.values.title || editorMarkdown
      ? {
          name: formik.values?.title ?? undefined,
          comment: formik.values.comment,
          otherInfo: prettifyForLLM({
            notepadContent: editorMarkdown?.substring(0, 2000),
          }),
        }
      : undefined;

  return (
    <MagicSuggestionsContextProvider
      context={suggestionsContext}
      onSelectSuggestion={onSelectSuggestion}
      includeFolders
    >
      <Flex flexGrow gap={24} direction="column" style={{ padding: '1rem' }}>
        <Header
          submitLoading={isPending}
          label={
            <StyledTextInput
              data-no-autofocus="true"
              variant="discreet"
              value={formik.values.title}
              name="title"
              onChange={formik.handleChange}
              placeholder="Untitled note"
              inputHeight="sm"
              autoFocus={false}
              containerProps={{
                flexGrow: 1,
              }}
            />
          }
        />
        <Flex direction="column" gap="elementsContainer" flexGrow>
          <TiptapWrapper>
            <Tiptap
              pastedContent={pastedContent}
              onChange={onFabricEditorChange}
              editorRef={setEditor}
              bottomElement={
                <MagicSuggestions
                  style={{
                    padding: '13px 8px',
                  }}
                />
              }
              autoFocus
            />
          </TiptapWrapper>
        </Flex>
        <SubmitFooter
          submitDisabled={
            formik.isSubmitting ||
            (formik.values.title === '' && !!editor?.isEmpty) ||
            !destinationParent?.id
          }
          submitLoading={isPending}
          /**
           * autosaving on unmount so we just need to close on submit
           * see @REF [save on unmount]
           */
          onSubmit={formik.submitForm}
        />
      </Flex>
    </MagicSuggestionsContextProvider>
  );
};

const StyledTextInput = styled(TextInput)`
  font-size: 16px;
  font-weight: 400;
`;

const TiptapWrapper = styled.div`
  flex-grow: 1;
  background: ${cssVar['color-bg-secondary-button']};
  ${mediaMobile} {
    margin-left: -1rem;
    margin-right: -1rem;
    margin-bottom: -1rem;
  }
  .tiptap {
    padding: 1rem;
  }
`;

import {
  ResourceMoveActions,
  useMutationMoveResourcesById,
} from '@/src/modules/resources/mutations/useMutationMoveResourcesById';
import { ResourceDetailFolder } from '@/src/modules/resources/resources.types';
import { toast } from '@/src/store/alerts';
import React, { CSSProperties } from 'react';
import {
  CustomEventMap,
  ObjectDragEvent,
  ObjectDragOver,
  isObjectsDroppableToResource,
} from '../../../types/draggable';
import { isTruthy } from '../../../utils/guards';

/**
 * handles dropping resources over an ref element
 * use isDraggingItemsOver return property to highlight the element being dragged over
 */
export const useDroppableAsTargetToResourceMove = <T extends HTMLElement>(
  ref: React.MutableRefObject<T | null>,
  resource?: Pick<ResourceDetailFolder, 'id' | 'name'>,
  options?: {
    enabled?: boolean;
    action?: ResourceMoveActions;
  },
) => {
  const enabled = options?.enabled ?? true;
  const { mutate: mutateMoveResourcesById } = useMutationMoveResourcesById();
  const [isDraggingItemsOver, setDraggingItemsOver] = React.useState(false);

  React.useEffect(() => {
    const htmlEl = ref?.current;
    if (!enabled || !htmlEl || !resource) {
      return;
    }

    const { id: targetResourceId } = resource;

    const onDragOver = (event: CustomEvent<ObjectDragOver>) => {
      if (!isObjectsDroppableToResource(targetResourceId, event.detail)) {
        /**
         * @TODO toast notification that user is moving resource to the same folder
         */
        return;
      }

      event.stopPropagation();
      setDraggingItemsOver(true);
    };

    const onDragOut = () => {
      setDraggingItemsOver(false);
    };

    const onDragDrop = async (event: CustomEvent<ObjectDragEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();

      if (!isObjectsDroppableToResource(targetResourceId, event.detail)) {
        toast({
          content: "Can't move. One of the items you're trying to move is destination folder.",
        });
        return;
      }

      setDraggingItemsOver(false);

      if (!event.detail.objectParentId) {
        return;
      }

      /**
       * event.detail.objectId might be included, might not
       * using set to get unique ids
       */
      const resourceToMoveIds = Array.from(
        new Set([...(event.detail.objectIds ?? []), event.detail.objectId].filter(isTruthy)),
      );

      mutateMoveResourcesById(
        {
          resourceIds: resourceToMoveIds,
          newParent: resource,
          action: options?.action ?? 'drop-into-folder',
        },
        {
          onSuccess: () => {
            const acceptEvent: CustomEventMap['object-drag-drop-accepted'] = new CustomEvent(
              'object-drag-drop-accepted',
              {
                detail: {
                  objectIds: resourceToMoveIds,
                },
              },
            );

            window.dispatchEvent(acceptEvent);
          },
        },
      );
    };

    htmlEl.addEventListener('object-drag-over', onDragOver);
    htmlEl.addEventListener('object-drag-out', onDragOut);
    htmlEl.addEventListener('object-drag-drop', onDragDrop);

    return () => {
      htmlEl.removeEventListener('object-drag-over', onDragOver);
      htmlEl.removeEventListener('object-drag-out', onDragOut);
      htmlEl.removeEventListener('object-drag-drop', onDragDrop);
    };
  }, [ref, setDraggingItemsOver, resource, enabled, mutateMoveResourcesById, options?.action]);

  const styles: CSSProperties = React.useMemo(() => {
    return isDraggingItemsOver
      ? {
          border: '1px solid rgba(42, 55, 238, 0.2)',
          background: 'rgba(42, 55, 238, 0.1)',
        }
      : {};
  }, [isDraggingItemsOver]);

  const droppableProps = React.useMemo(() => {
    return {
      ['data-drag-over']: isDraggingItemsOver,
      ['data-droppable']: enabled,
    };
  }, [isDraggingItemsOver, enabled]);

  return {
    isDraggingItemsOver,
    droppableProps,
    styles,
  };
};

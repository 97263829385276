import UploadIcon from '@/public/images/icons/Upload.svg';
import useAuthStore from '@/src/hooks/auth';
import useFilesUpload from '@/src/hooks/fileUpload';
import CloseIcon from '@/src/icons/CloseIcon';
import { storeDataTransfer, StoredDataTransfer } from '@/src/lib/clipboard';
import { pick } from '@/src/lib/store';
import { NewResourceType } from '@/src/modules/resources/components/NewResource/config';
import { useQueryFolder } from '@/src/modules/resources/queries/useQueryFolder';
import useUIStore from '@/src/store/ui';
import { isInputElement } from '@/src/utils/elements';
import clsx from 'clsx';
import gsap, { Cubic } from 'gsap';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { setTimeout } from 'timers';
import { shallow } from 'zustand/shallow';
import styles from './DragAndDropArea.module.css';

const IMAGE_URL_REGEXP = /^https:\/\/.*\.(jpe?g|png|gif|webp|svg|bmp|ico|tiff?)/i;

type DragAndDropProps = {
  onFileNote: () => void;
  onUrlNote: (url: string) => void;
  onTextNote: (url: string, data?: StoredDataTransfer) => void;
};

function fadeInAnimation() {
  gsap.fromTo('#drag-and-drop-backdrop', { opacity: 0 }, { opacity: 1, duration: 0.25 });
  gsap.fromTo(
    '#upload-icon',
    { opacity: 0, transform: 'scale(0)' },
    { opacity: 1, transform: 'scale(1)', ease: Cubic.easeOut, duration: 0.2, delay: 0.1 },
  );
}

function fadeOutAnimation(onComplete?: () => void) {
  gsap.fromTo(
    '#upload-icon',
    { opacity: 1, transform: 'scale(1)' },
    { opacity: 0, transform: 'scale(0)', ease: Cubic.easeOut, duration: 0.2 },
  );
  gsap.fromTo(
    '#drag-and-drop-backdrop',
    { opacity: 1 },
    { opacity: 0, duration: 0.25, delay: 0.1, onComplete },
  );
}
type DragAndDropPlaceholderProps = {
  message: string;
  error: string;
  setDraggingOver: (value: boolean) => void;
};

const DragAndDropPlaceholder = React.memo<DragAndDropPlaceholderProps>(
  ({ message, error, setDraggingOver }) => {
    useEffect(() => {
      fadeInAnimation();
    }, []);

    const handleDragLeave = useCallback(() => {
      fadeOutAnimation(() => setDraggingOver(false));
    }, [setDraggingOver]);

    return (
      <div
        id="drag-and-drop-backdrop"
        className={clsx(styles.backdrop, error ? styles.error : '')}
        onDragLeave={handleDragLeave}
        onClick={handleDragLeave}
      >
        <div id="drag-and-drop-area" className={styles.dragAndDropArea}>
          <button className={styles.closeButton} onClick={handleDragLeave}>
            <CloseIcon />
          </button>

          <UploadIcon id="upload-icon" height={120} width={120} />
          <span id="upload-message" className={styles.message}>
            {error || message}
          </span>
        </div>
      </div>
    );
  },
);

DragAndDropPlaceholder.displayName = 'DragAndDropPlaceholder';

export function DragAndDropArea({ onFileNote, onUrlNote, onTextNote }: DragAndDropProps) {
  const { authStatus, isLoggedIn } = useAuthStore(
    (state) => pick(state, ['authStatus', 'isLoggedIn']),
    shallow,
  );
  const [isDraggingOver, setDraggingOver] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<string>('');

  // Temporary solution for disabling drag and drop in the integration folder
  const router = useRouter();
  const folderId = router.query.folderId as string;

  const { folder } = useQueryFolder(folderId);
  const isIntegrationFolder = folder?.root?.type === 'INTEGRATION';
  const expandedFdocId = useUIStore((state) => state.expandedFdocId, shallow);
  const isNewNotepadModalOpen = useUIStore(
    (state) => state.isNewModalOpen && state.newModalOptions.type === NewResourceType.Note,
    shallow,
  );
  const inImportPage = router.pathname === '/import';

  const shouldHandlePasteOrDrop =
    !expandedFdocId && !isIntegrationFolder && !isNewNotepadModalOpen && !inImportPage;

  const shouldAttachPasteOrDropListeners = authStatus === 'authenticated' && isLoggedIn;

  const onFileUpload = useCallback(() => {
    setDraggingOver(false);
    onFileNote();
  }, [onFileNote]);

  const handleFilesDrop = useFilesUpload(onFileUpload);

  const handleURLDrop = useCallback(
    async (url: string) => {
      const results = url.match(IMAGE_URL_REGEXP);
      const href = results ? results[0] : url;
      onUrlNote(href);
    },
    [onUrlNote],
  );

  const handleTextDrop = useCallback(
    async (text: string, data?: StoredDataTransfer) => {
      const span = document.createElement('span');
      span.innerHTML = text;
      const content = span.textContent || span.innerText;
      onTextNote(content, data);
    },
    [onTextNote],
  );

  const handlePaste = useCallback(
    (text: string, data?: StoredDataTransfer) => {
      try {
        const { protocol, href } = new URL(text);
        if (['http:', 'https:'].includes(protocol)) {
          onUrlNote(href);
        } else {
          throw new Error('Invalid protocol. Only http and https is allowed.');
        }
      } catch (error) {
        onTextNote(text, data);
      }
    },
    [onUrlNote, onTextNote],
  );

  useEffect(() => {
    if (!shouldAttachPasteOrDropListeners) return;

    const pasteHandler = async (event: ClipboardEvent) => {
      if (!shouldHandlePasteOrDrop) return;

      const items = event.clipboardData?.items;
      const otherFiles = event.clipboardData?.files;

      // get all possible files from clipboard
      const files = items?.length
        ? Array.from(items)
            .filter((item) => item.kind === 'file')
            .filter(Boolean)
        : [];

      // Files from the items array always take precedence over files from the files array,
      // that's because the files array contains only File objects, while the items array
      // contains DataTransferItem objects, which can be either files or directories.
      if (files?.length) return handleFilesDrop(files);
      if (otherFiles?.length) return handleFilesDrop(Array.from(otherFiles));

      if (!event.clipboardData) return;

      // if we are focusing an interactive element, don't paste
      if (isInputElement(document.activeElement, true)) return;
      if ((document.activeElement as HTMLElement).isContentEditable) return;

      const text = event.clipboardData.getData('text/plain');
      if (!text) return setMessage('No text was detected');

      try {
        handlePaste(text, storeDataTransfer(event.clipboardData));
        event.preventDefault();
      } catch (error: unknown) {
        if (error instanceof Error) setMessage(error.message);
        console.error(error);
      }
    };

    const dragOverHandler = (event: DragEvent) => {
      if (!shouldHandlePasteOrDrop) {
        return setDraggingOver(false);
      }

      // prevent default dragover event to allow drop
      event.preventDefault();

      setError('');

      if (!event.dataTransfer) return setDraggingOver(false);

      setDraggingOver(true);

      const items = Array.from(event.dataTransfer.items).filter((item) => !!item);

      const files = Array.from({ length: items.length })
        .map((_value, i) => items[i])
        .filter((item) => item.kind === 'file');

      const otherFiles = Array.from(event.dataTransfer.files).filter((file) => !!file);

      if (files.length || otherFiles.length) {
        return setMessage('Drag files here to add to your library');
      } else if (items.length > 0 && items[0].kind === 'string') {
        setMessage('Drag a URL to add to your library');
      }
      setDraggingOver(true);
    };

    // same for drop event (e.g. drag and drop a link from browser)
    const dropHandler = async (event: DragEvent) => {
      event.preventDefault();

      if (!shouldHandlePasteOrDrop) {
        return setDraggingOver(false);
      }

      if (!event.dataTransfer) return setDraggingOver(false);

      const items = event.dataTransfer.items;
      const files = Array.from(items).filter((item) => item.kind === 'file');

      const otherFiles = event.dataTransfer.files;

      const item = event.dataTransfer.items[0];

      // Files from the items array always take precedence over files from the files array,
      // that's because the files array contains only File objects, while the items array
      // contains DataTransferItem objects, which can be either files or directories.
      if (files.length > 0) {
        try {
          await handleFilesDrop(files);
        } catch (error: unknown) {
          if (error instanceof Error) setError(error.message);
          console.error(error);

          setTimeout(() => {
            fadeOutAnimation(() => setDraggingOver(false));
          }, 2000);
        }
        return;
      }

      if (otherFiles?.length) return handleFilesDrop(Array.from(otherFiles));

      if (item.kind === 'string') {
        item.getAsString(async (text) => {
          let isUrl: boolean;
          try {
            new URL(text);
            isUrl = true;
          } catch (error) {
            isUrl = false;
          }

          try {
            const handler = isUrl ? handleURLDrop : handleTextDrop;
            await handler(text);
            setDraggingOver(false);
          } catch (error: unknown) {
            if (error instanceof Error) setError(error.message);
            console.error(error);

            setTimeout(() => {
              fadeOutAnimation(() => setDraggingOver(false));
            }, 2000);
          }
        });
      } else {
        setDraggingOver(false);
      }
    };

    const keyDownHandler = (e: KeyboardEvent) => {
      setDraggingOver((draggingOver) => {
        if (e.key === 'Escape' && draggingOver) {
          e.stopPropagation();
          return false;
        }

        return draggingOver;
      });
    };

    document.addEventListener('paste', pasteHandler);
    document.addEventListener('dragover', dragOverHandler);
    document.addEventListener('drop', dropHandler);
    document.addEventListener('keydown', keyDownHandler, { capture: true });

    return () => {
      document.removeEventListener('paste', pasteHandler);
      document.removeEventListener('dragover', dragOverHandler);
      document.removeEventListener('drop', dropHandler);
      document.removeEventListener('keydown', keyDownHandler, { capture: true });
    };
  }, [
    handleURLDrop,
    handleTextDrop,
    handlePaste,
    handleFilesDrop,
    shouldAttachPasteOrDropListeners,
    shouldHandlePasteOrDrop,
  ]);

  return isDraggingOver ? (
    <DragAndDropPlaceholder setDraggingOver={setDraggingOver} message={message} error={error} />
  ) : null;
}

import { Fdoc } from './api';

export interface ObjectDragOver {
  objectParentId: string | null | undefined;
  objectId: string;
  objectIds?: string[];
  x: number;
  y: number;
  rect: {
    top: number;
    left: number;
    width: number;
    height: number;
  };
  width: number;
  height: number;
}

export interface ObjectDragEvent {
  objectParentId: string | null | undefined;
  objectId: string;
  objectIds?: string[];
}

export interface CustomEventMap {
  'object-drag-over': CustomEvent<ObjectDragOver>;
  'object-drag-out': CustomEvent<ObjectDragEvent>;
  'object-drag-drop': CustomEvent<ObjectDragEvent>;
  'object-drag-drop-accepted': CustomEvent<{
    objectIds: string[];
  }>;
}

declare global {
  // add to any element
  interface HTMLElement {
    addEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void,
    ): void;
    removeEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void,
    ): void;
  }
  interface HTMLDivElement {
    addEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void,
    ): void;
    removeEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void,
    ): void;
  }
}

export const isObjectsDroppableToResource = (
  toResource: Fdoc['id'],
  dragOverObject: ObjectDragEvent,
) => {
  /**
   * same folder
   */
  if (dragOverObject.objectParentId === toResource) {
    return false;
  }

  /**
   * is moving self to self
   */
  if (dragOverObject.objectId === toResource || dragOverObject.objectIds?.includes(toResource)) {
    return false;
  }

  return true;
};

import { useIsAnyRadixModalOpenCount } from '@/src/modules/ui/components/Modal/useIsAnyRadixModalOpened';
import useUIStore from '@/src/store/ui';
import { shallow } from 'zustand/shallow';

export const useIsAnyModalOpen = () => {
  const { expandedFdocId, isNewModalOpen, sidebarOpen } = useUIStore(
    (state) => ({
      sidebarOpen: state.sidebarOpen,
      expandedFdocId: state.expandedFdocId,
      isNewModalOpen: state.isNewModalOpen,
    }),
    shallow,
  );

  const radixModalsCount = useIsAnyRadixModalOpenCount();

  return !!sidebarOpen || !!expandedFdocId || !!isNewModalOpen || radixModalsCount > 0;
};

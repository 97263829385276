import { useWindowEvent } from '@/src/hooks/useWindowEvent';
import { useIsAnyRadixModalOpenCount } from '@/src/modules/ui/components/Modal/useIsAnyRadixModalOpened';
import { setNewModalOpen } from '@/src/store/ui';
import isHotkey from 'is-hotkey';

export const useKbdAltN_openNewResourceSelector = () => {
  const openedModalsCount = useIsAnyRadixModalOpenCount();

  useWindowEvent(
    'keydown',
    (e) => {
      if (isHotkey('alt+n', e)) {
        e.preventDefault();
        e.stopPropagation();
        setNewModalOpen(true);
      }
    },
    {
      enabled: openedModalsCount === 0,
    },
  );
};

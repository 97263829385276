import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { ResourceFilterType } from '@/src/modules/resources/contants/resourceType';
import { useQueryResourceContextSearch } from '@/src/modules/resources/queries/useQueryResourceContextSearch';
import { FabricQueryMode } from '@fabric/woody-client';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { OfViewMode } from '../components/ViewModeSwitcher/ViewModeSwitcher';
import { useAuthUser } from './auth';
import { useResponsive } from './responsive';

interface IGlobalSearchContext {
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  viewMode: OfViewMode;
  setViewMode: React.Dispatch<React.SetStateAction<OfViewMode>>;
  filter: ResourceFilterType;
  setFilter: React.Dispatch<React.SetStateAction<ResourceFilterType>>;
  filtersVisible: boolean;
  setFiltersVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectedIndex: number | null;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number | null>>;
  zoomLevel: number;
  setZoomLevel: React.Dispatch<React.SetStateAction<number>>;
  scrollPosition: number;
  setScrollPosition: React.Dispatch<React.SetStateAction<number>>;
  storedScrollPosition: number | null;
  setStoredScrollPosition: React.Dispatch<React.SetStateAction<number | null>>;
  lastInteraction: number;
  setLastInteraction: React.Dispatch<React.SetStateAction<number>>;
}

const defaultValues: IGlobalSearchContext = {
  // default values
  query: '',
  setQuery: () => {},
  viewMode: 'List',
  setViewMode: () => {},
  filter: 'All',
  setFilter: () => {},
  filtersVisible: false,
  setFiltersVisible: () => {},
  selectedIndex: 0,
  setSelectedIndex: () => {},
  zoomLevel: 0.9,
  setZoomLevel: () => {},
  scrollPosition: 0,
  setScrollPosition: () => {},
  storedScrollPosition: null,
  setStoredScrollPosition: () => {},
  lastInteraction: 0,
  setLastInteraction: () => {},
};

const GlobalSearchContext = React.createContext<IGlobalSearchContext>(defaultValues);

export const GlobalSearchProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [query, setQuery] = useState('');
  const [viewMode, setViewMode] = useState<OfViewMode>('Grid');
  const [filter, setFilter] = useState<ResourceFilterType>('All');
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(0);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [storedScrollPosition, setStoredScrollPosition] = useState<null | number>(null);
  const [lastInteraction, setLastInteraction] = useState(0);

  useEffect(() => {
    setLastInteraction(Date.now());
  }, [query, viewMode, filter, filtersVisible, selectedIndex, zoomLevel, scrollPosition]);

  const value = useMemo(
    () => ({
      query,
      setQuery,
      viewMode,
      setViewMode,
      filter,
      setFilter,
      filtersVisible,
      setFiltersVisible,
      selectedIndex,
      setSelectedIndex,
      zoomLevel,
      setZoomLevel,
      scrollPosition,
      setScrollPosition,
      storedScrollPosition,
      setStoredScrollPosition,
      lastInteraction,
      setLastInteraction,
    }),
    [
      query,
      viewMode,
      filter,
      filtersVisible,
      selectedIndex,
      zoomLevel,
      scrollPosition,
      storedScrollPosition,
      lastInteraction,
    ],
  );

  return <GlobalSearchContext.Provider value={value}>{children}</GlobalSearchContext.Provider>;
};

const useGlobalSearchStore = (
  _id: string,
  listParentRef: HTMLElement | null,
  disabled: boolean = false,
) => {
  const {
    query,
    setQuery,
    viewMode,
    setViewMode,
    filter,
    setFilter,
    filtersVisible,
    setFiltersVisible,
    selectedIndex,
    setSelectedIndex,
    zoomLevel,
    setZoomLevel,
    scrollPosition,
    setScrollPosition,
    storedScrollPosition,
    setStoredScrollPosition,
    lastInteraction,
    setLastInteraction,
  } = useContext(GlobalSearchContext);

  const user = useAuthUser();
  const { track } = useAnalytics();
  const storedQuery = useRef<string>(query);

  useEffect(() => {
    if (query === storedQuery.current) return;

    const debounce = setTimeout(() => {
      storedQuery.current = query;

      track(AnalyticsEvents.Search, {
        has_query: true,
        action: 'global_search',
      });
    }, 3000);

    return () => clearTimeout(debounce);
  }, [query, track, user]);

  const { resources, fetchNextPage, isLoading } = useQueryResourceContextSearch(
    {
      mode: FabricQueryMode.Hybrid,
      text: query,
      filters: {
        hasSlug: false,
      },
    },
    {
      enabled: !disabled && query.length > 0,
    },
  );

  const onSearchKeyDown = (e: React.KeyboardEvent) => {
    // if it's space and we have a selection we ignore, but if it's ESC clear the selection
    if (e.key === ' ' && selectedIndex !== null) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    // if the key is down or up we will move the selection
    if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp') return;

    e.preventDefault();
    e.stopPropagation();

    if (!resources.length) return;

    if (selectedIndex === null) {
      setSelectedIndex(e.key === 'ArrowDown' ? 0 : resources.length - 1);
      return;
    }

    const newIndex =
      ((e.key === 'ArrowDown' ? selectedIndex + 1 : selectedIndex - 1) + resources.length) %
      resources.length;

    setSelectedIndex(newIndex);
  };

  // when the query changes we want to reset the selected index
  useEffect(() => {
    setSelectedIndex(null);
  }, [query, setSelectedIndex]);

  const { isMobile } = useResponsive();

  useEffect(() => {
    if (!isMobile()) return;

    setViewMode('Grid');
  }, [isMobile, setViewMode]);

  const scrollPositionRef = useRef(scrollPosition);
  useEffect(() => {
    scrollPositionRef.current = scrollPosition;
  }, [scrollPosition]);

  useEffect(() => {
    return () => {
      setStoredScrollPosition(scrollPositionRef.current);
    };
  }, [setStoredScrollPosition]);

  useEffect(() => {
    if (!listParentRef || isLoading || storedScrollPosition === null) return;

    let lastTimeout: number;

    const scrollTo = () => {
      const list = listParentRef.querySelector('.dashboard_scrollbar');
      if (!list) return;

      list.scrollTo({ top: storedScrollPosition });

      // now we need to check if the scroll position is the same as the stored one
      // if it is we can clear it otherwise we keep trying to scroll to it

      lastTimeout = window.setTimeout(() => {
        if (list.scrollTop === storedScrollPosition) {
          setStoredScrollPosition(null);
        }

        // if it's not the same we try again
        else scrollTo();
      }, 100);
    };

    scrollTo();

    return () => clearInterval(lastTimeout);
  }, [isLoading, listParentRef, setStoredScrollPosition, storedScrollPosition]);

  useEffect(() => {
    // if the last interaction was more than 10 minutes ago we reset the search
    if (!lastInteraction || Date.now() - lastInteraction < 10 * 60 * 1000) return;

    setQuery('');
    setFilter('All');
    setFiltersVisible(false);
    setSelectedIndex(null);
    setZoomLevel(0.9);
    setScrollPosition(0);
    setStoredScrollPosition(null);
    setLastInteraction(0);
  }, [
    lastInteraction,
    setQuery,
    setFilter,
    setFiltersVisible,
    setSelectedIndex,
    setZoomLevel,
    setScrollPosition,
    setStoredScrollPosition,
    setLastInteraction,
  ]);

  return {
    query,
    setQuery,
    viewMode,
    setViewMode,
    resources,
    filter,
    setFilter,
    filtersVisible,
    setFiltersVisible,
    selectedIndex,
    setSelectedIndex,
    onSearchKeyDown,
    zoomLevel,
    setZoomLevel,
    storedScrollPosition,
    scrollPosition,
    setScrollPosition,
    fetchNextPage,
  };
};

// eslint-disable-next-line import/no-unused-modules
export default useGlobalSearchStore;

import { useAuthIsLoggedIn } from '@/src/hooks/auth';
import usePrevious from '@/src/hooks/previous';
import AnimatedMascotButton from '@/src/modules/assistant/components/AnimatedMascotButton';
import { useAssistantStore } from '@/src/modules/assistant/stores/assistantStore';
import { useIsAnyModalOpen } from '@/src/modules/ui/components/Modal/useIsAnyModalOpen';
import { useSearchOverlay } from '@/src/store/ui';
import clsx from 'clsx';
import { motion, useWillChange } from 'framer-motion';
import { default as React } from 'react';
import styles from './AssistantButton.module.scss';

const AssistantButton: React.FC<{
  onClick?: () => void;
  className?: string;
  style?: React.CSSProperties;
}> = ({ onClick, className, style }) => {
  const isLoggedIn = useAuthIsLoggedIn();
  const { chatAssistantOpen, chatAssistantFullscreen } = useAssistantStore();
  const previousChatAssistantOpen = usePrevious(chatAssistantOpen);

  const willChange = useWillChange();

  const isAnyModalOpen = useIsAnyModalOpen();
  const { isOverlayOpen: isSearchOverlayOpen } = useSearchOverlay();

  const isButtonHidden = isSearchOverlayOpen || isAnyModalOpen || chatAssistantOpen;

  if (!isLoggedIn) {
    return null;
  }

  return (
    <div className={clsx(styles.assistant_button_container, className)} style={style}>
      <AnimatedMascotButton
        isVisible={!isButtonHidden}
        key="mascot-button"
        onClick={() => {
          onClick?.();
        }}
        wasChatAssistantOpen={previousChatAssistantOpen}
      />

      {!chatAssistantOpen && (
        <motion.div
          key={chatAssistantFullscreen ? 'chatbot-fullscreen' : 'chatbot'}
          layoutId={chatAssistantFullscreen ? 'chatbot-fullscreen' : 'chatbot'}
          data-key={chatAssistantFullscreen ? 'chatbot-fullscreen' : 'chatbot'}
          transition={{
            delay: 0.1,
            duration: 0.3,
            type: 'spring',
            stiffness: 500,
            damping: 40,
          }}
          className={styles.assistant_chatbot_placeholder}
          style={{ willChange }}
        />
      )}
    </div>
  );
};

export default AssistantButton;

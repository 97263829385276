import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { useWoody } from '@/src/services/woody/woody';
import { QueryObserverOptions, keepPreviousData, useQuery } from '@tanstack/react-query';
import { resourceDetailsQueryKeys } from './resourceDetailsQueryKeys';
import { LinkPreview } from '../resource-details.types';
import isUrl from 'is-url';
import { isString } from '@/src/lib/utils';

/**
 * Fetches the link preview for the given URL
 *
 * @param string
 * @param queryOptions
 * @returns
 */
const useQueryLinkPreview = (
  url?: string,
  queryOptions?: Partial<QueryObserverOptions<LinkPreview>>,
) => {
  const { client } = useWoody();

  const query = useQuery({
    queryKey: resourceDetailsQueryKeys.linkPreview(url),
    queryFn: async (params) => {
      const safeUrl = params.queryKey[1] as string;
      return client.v2('/v2/link', {
        query: {
          url: safeUrl,
        },
      });
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    refetchInterval: (query) => {
      // If not fresh we retry in the background with increasing intervals based on the error count.
      return query.state.error ? 1000 * 60 * query.state.fetchFailureCount : false;
    },
    retry: false,
    ...queryOptions,
    enabled: isQueryEnabled([!!url, queryOptions?.enabled, isString(url) && isUrl(url)]),
  });

  return {
    ...query,
  };
};

export { useQueryLinkPreview };

import { isNextDev } from '@/src/constants/env';
import { NextSeoProps } from 'next-seo';

export const DefaultSEO: NextSeoProps = {
  title: isNextDev ? '[D] Fabric' : 'Fabric – think better, together',
  description:
    'Your collaborative internet filespace. A note-taking and capture tool that just works. Designed for busy makers and thinkers.',
  openGraph: {
    title: '[Local] Fabric – think better, together',
    url: process.env.NEXT_PUBLIC_WEBSITE_URL ?? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`,
    description:
      'Your collaborative internet filespace. A note-taking and capture tool that just works. Designed for busy makers and thinkers.',
    images: [
      {
        url: `${
          process.env.NEXT_PUBLIC_WEBSITE_URL ?? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
        }/images/fabric-social-image-sm-c.png`,
        width: 1200,
        height: 630,
      },
    ],
    site_name: 'Fabric',
  },
  twitter: {
    handle: '@fabricinternet',
    site: '@fabricinternet',
    cardType: 'summary_large_image',
  },
};

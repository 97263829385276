import React from 'react';

import { useResponsive } from '@/src/hooks/responsive';
import { useDomContentRect } from '@/src/hooks/useDomRect';

import { useClientLayoutEffect } from '@/src/hooks/useClientLayoutEffect';
import SearchIcon from '@/src/icons/SearchIcon';
import useBulkUploaderStore from '@/src/lib/bulkUploader/store';
import { IconHamburgerFooter } from '@/src/modules/icons';
import { ModalNewResourceSelector } from '@/src/modules/resources/components/ModalNewResourceSelector';
import { NewResourceTypesOptions } from '@/src/modules/resources/components/NewResource/config';
import { useQueryFolder } from '@/src/modules/resources/queries/useQueryFolder';
import { Button } from '@/src/modules/ui/components/Button';
import { mediaHover } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import useUIStore, { setNewModalOpen, useSearchOverlay } from '@/src/store/ui';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import styled, { createGlobalStyle } from 'styled-components';
import { shallow } from 'zustand/shallow';
import styles from './DashboardFooter.module.scss';

const ButtonIconDarkText = styled(Button).attrs({
  variant: 'transparent',
  shape: 'square',
})`
  color: ${cssVar['color-text-primary']};
  ${mediaHover} {
    &:hover {
      background: ${cssVar['color-bg-tertiary']};
    }
  }
  outline: none !important;
`;

const FooterGlobalStyles = createGlobalStyle<{ footerHeight?: number }>`
:root {
  ${(p) => {
    return `
    --footer-height: ${p.footerHeight || 0}px;
    `;
  }}
  }
`;

const DashboardFooter: React.FC = () => {
  const router = useRouter();
  const { isMobileView } = useResponsive();
  const { openOverlay, closeOverlay, isOverlayOpen: isSearchOverlayOpen } = useSearchOverlay();

  const { sharedFiles } = useBulkUploaderStore((state) => {
    return {
      sharedFiles: state.sharedFiles,
    };
  }, shallow);

  const flatFiles = React.useMemo(() => {
    return Object.entries(sharedFiles).flatMap(([key, value]) => {
      return value.map((file) => {
        return {
          ...file,
          key,
        };
      });
    });
  }, [sharedFiles]);

  const isHome = router.pathname === '/';
  const isSaved = router.pathname === '/saved';
  const isTimeline = router.pathname === '/timeline';
  const insideSpaces = router.pathname.startsWith('/spaces/');
  const isFolder = router.pathname === '/folders' || router.pathname.startsWith('/folders/');
  // if desktop, make sure we are not in the home screen or the /spaces screen (not the /spaces/:id screens)
  const isSpacesRoot = router.pathname === '/spaces';
  const isNotifications = router.pathname === '/notifications';
  const isImport = router.pathname === '/import';
  const isTags = router.pathname === '/tags';
  const isExpandedTagPage = router.route.startsWith('/tags/');

  const folderId = router.query.folderId as string;

  const { folder } = useQueryFolder(folderId, {
    enabled: !!folderId,
  });

  const isIntegrationFolder = folder?.root?.type === 'INTEGRATION';

  const showFooter = isMobileView
    ? isHome ||
      isSaved ||
      isTimeline ||
      insideSpaces ||
      isFolder ||
      isSpacesRoot ||
      isNotifications ||
      isImport ||
      isTags ||
      isExpandedTagPage
    : (isSaved || isTimeline || insideSpaces || isFolder || isHome || isExpandedTagPage) &&
      !isIntegrationFolder;

  const { sidebarOpen, setSidebarOpen } = useUIStore(
    (state) => ({
      sidebarOpen: state.sidebarOpen,
      setSidebarOpen: state.setSidebarOpen,
    }),
    shallow,
  );

  const toggleSidebar = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setSidebarOpen(!sidebarOpen);
  };

  useClientLayoutEffect(() => {
    if (!showFooter || !isMobileView) return;

    document.documentElement.style.setProperty(
      '--safe-margin-offset-bottom',
      `calc(env(safe-area-inset-bottom) + 80px)`,
    );

    return () =>
      document.documentElement.style.setProperty(
        '--safe-margin-offset-bottom',
        'env(safe-area-inset-bottom)',
      );
  }, [showFooter, isMobileView]);

  const footerContentRef = React.useRef<HTMLDivElement>(null);
  const [footerRect] = useDomContentRect(footerContentRef.current);

  if (!showFooter) return <FooterGlobalStyles footerHeight={0} />;

  return (
    <footer className={clsx(styles.footer, flatFiles.length > 0 && styles.footer_mb)}>
      <FooterGlobalStyles footerHeight={footerRect?.height} />
      <div className={styles.mobile_content} ref={footerContentRef}>
        <ButtonIconDarkText onClick={toggleSidebar}>
          <IconHamburgerFooter
            style={{
              width: 24,
              height: 24,
            }}
          />
        </ButtonIconDarkText>

        <ModalNewResourceSelector
          contentPositionAlignment="top-center"
          buttonProps={{
            style: {
              width: 48,
              height: 40,
              border: 'none',
            },
          }}
        />

        <ButtonIconDarkText
          onClick={() => (isSearchOverlayOpen ? closeOverlay() : openOverlay('global'))}
        >
          <SearchIcon style={{ width: 22 }} />
        </ButtonIconDarkText>
      </div>

      <div className={styles.content}>
        {NewResourceTypesOptions.map((option) => (
          <div className={styles.new_modal_button_container} key={option.value}>
            <button
              className={styles.new_modal_button}
              onClick={() =>
                setNewModalOpen(true, {
                  type: option.value,
                })
              }
              data-testid={`new-modal-button-${option.value}`}
            >
              <option.icon style={option.iconStyle} />
            </button>
          </div>
        ))}
      </div>
    </footer>
  );
};

export default DashboardFooter;

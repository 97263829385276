import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import ExpandedFdoc from '@/src/components/ExpandedFdoc/ExpandedFdoc';
import { getEmptyYdocBinaryState } from '@/src/components/Tiptap/utils/getEmptyYdocBinaryState';
import { useAuthUser } from '@/src/hooks/auth';
import { ResourceDataContextProvider } from '@/src/modules/resource-detail/components/context/ResourceDataContextProvider';
import { NewResourceType } from '@/src/modules/resources/components/NewResource/config';
import { useNewResourceContext } from '@/src/modules/resources/components/NewResource/context/ModalNewResourceContext';
import { useMutationCreateResourceV2 } from '@/src/modules/resources/mutations/useMutationCreateResourceV2';
import { useQueryResourceDetail } from '@/src/modules/resources/queries/useQueryResourceDetail';
import { ResourceDetailNotepad } from '@/src/modules/resources/resources.types';
import { createOptimisticResource } from '@/src/modules/resources/utils/createOptimisticResource';
import useUIStore from '@/src/store/ui';
import { OptimisticDraft } from '@/src/types/draftable';
import { shallow } from 'zustand/shallow';

/**
 * could deservers some more love, striped out old FdocNew
 */
export const ModalContentNoteDesktop: React.FC = () => {
  const formRef = useRef<HTMLFormElement | null>(null);

  const { newModalOptions, setNewModalOptions } = useUIStore(
    (s) => ({ newModalOptions: s.newModalOptions, setNewModalOptions: s.setNewModalOptions }),
    shallow,
  );

  const { type, shared } = newModalOptions;
  const { destinationParent, selectedTags, clearSelectedTags, handleClose, onSelectTag } =
    useNewResourceContext();

  const selectedTagsRef = useRef(selectedTags);
  selectedTagsRef.current = selectedTags;

  const { mutateAsync: mutateCreateResource, ...mutationCreateResource } =
    useMutationCreateResourceV2();

  useEffect(() => {
    if (!type) return;

    setTimeout(() => {
      // focus first input or contenteditable inside formRef
      // if shared we focus the second input (or only one if there is only one)
      // for inputs ignore type=file

      const foundInputs = formRef.current?.querySelectorAll(
        'input:not([type="file"]):not([data-no-autofocus="true"]), textarea, [contenteditable]',
      );

      if (!shared) {
        /**
         * find first non-empty input and focus it
         */
        const item = Array.from(foundInputs || []).find((item) => {
          if ('value' in item) {
            return (item.value as unknown as string).length === 0;
          }
          return false;
        });

        if (item) {
          (item as HTMLElement).focus();
          return;
        }
      }

      const input = !shared
        ? (foundInputs?.[0] as HTMLElement | null)
        : // second input or first if there is only one
          (foundInputs?.[foundInputs.length > 1 ? 1 : 0] as HTMLElement | null);

      if (input) input.focus();
    }, 10);
  }, [shared, type]);

  const savingNotepad = useRef(false);
  const [lastCreatedNotepadId, setLastCreatedNotepadId] = useState<string | null>(null);
  const lastCreatedNotepadIdRef = useRef<string | null>(null);

  useEffect(() => {
    lastCreatedNotepadIdRef.current = lastCreatedNotepadId;
  }, [lastCreatedNotepadId]);

  const { resource: lastCreatedNotepadResource } = useQueryResourceDetail(
    lastCreatedNotepadId ?? undefined,
    {
      refetchInterval: 45000,
    },
  );

  /**
   * draft in v2 data structure
   * keeping v1 before the notepad refactor
   */
  const user = useAuthUser();
  const draftNotepadResource: OptimisticDraft<ResourceDetailNotepad> = useMemo(() => {
    return createOptimisticResource(
      {
        createType: 'note',
        body: {
          name: null,
          ydoc: Array.from(getEmptyYdocBinaryState()),
          parentId: null as any, // it's fine, we're not sending this to api
        },
        optimisticData: {
          tags: [],
          parent: null,
          preview: {
            content: '',
          },
        },
      },
      user!,
    ) as OptimisticDraft<ResourceDetailNotepad>;
  }, []);

  const handleNewNotepad = useCallback(
    async (notepadData: { name: string | null; content?: string; state?: Uint8Array }) => {
      if (
        savingNotepad.current ||
        lastCreatedNotepadIdRef.current ||
        lastCreatedNotepadResource ||
        lastCreatedNotepadId ||
        mutationCreateResource.isPending
      )
        return;

      savingNotepad.current = true;

      const tags = selectedTagsRef.current;

      clearSelectedTags();

      const resource = await mutateCreateResource({
        data: {
          createType: 'note',
          body: {
            name: notepadData.name || null,
            parentId: destinationParent!.id,
            ydoc: Array.from(notepadData.state || getEmptyYdocBinaryState()),
            tags: tags.map((tag) => (tag.isDraft ? { name: tag.name } : { id: tag.id })),
          },
          optimisticData: {
            tags,
            parent: destinationParent!,
            preview: {
              content: notepadData.content,
            },
          },
        },
      });

      if (!resource) {
        savingNotepad.current = false;
        return;
      }

      setNewModalOptions((options) => {
        /**
         * race condition
         * if the user closes the modal before the fdoc is created (handleClose)
         * we don't want to update the open options to type note again
         * because afterwards there's no way to reset the state.
         * This then blocks the drag and drop paste functionality (disabled when isNewOpenmodal has type)
         */
        if ('type' in options) {
          return {
            ...options,
            type: NewResourceType.Note,
            fabricEditorValue: undefined,
          };
        }
        return options;
      });

      lastCreatedNotepadIdRef.current = resource.id;
      setLastCreatedNotepadId(resource.id);

      savingNotepad.current = false;

      return resource.id;
    },
    [
      lastCreatedNotepadResource,
      lastCreatedNotepadId,
      mutationCreateResource.isPending,
      mutateCreateResource,
      destinationParent,
      setNewModalOptions,
      clearSelectedTags,
    ],
  );

  const [notepadSidebarOpen, setNotepadSidebarOpen] = useState(false);

  const expandedResource = useMemo(() => {
    return lastCreatedNotepadResource
      ? {
          ...lastCreatedNotepadResource,
          draftId: draftNotepadResource.id,
        }
      : draftNotepadResource;
  }, [lastCreatedNotepadResource, draftNotepadResource]);

  const expandedFdocDraftProps = useMemo(
    () =>
      !lastCreatedNotepadResource
        ? {
            draftSaveFdoc: handleNewNotepad,
            draftOnSelectTag: onSelectTag,
            draftTags: selectedTags,
          }
        : {},
    [handleNewNotepad, lastCreatedNotepadResource, onSelectTag, selectedTags],
  );

  return (
    <ResourceDataContextProvider resource={expandedResource}>
      <ExpandedFdoc
        key="expanded-fdoc"
        onClose={handleClose}
        overrideSidebarOpen={notepadSidebarOpen}
        setOverrideSidebarOpen={setNotepadSidebarOpen}
        {...expandedFdocDraftProps}
      />
    </ResourceDataContextProvider>
  );
};

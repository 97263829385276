import ChevronRightThinIcon from '@/public/images/icons/ChevronRightThin.svg';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { findNode, findParents } from './utils';

import { useResponsive } from '@/src/hooks/responsive';
import { pick } from '@/src/lib/store';
import { chainShortcutHandlers } from '@/src/modules/keyboardShortcuts/utils';
import { useDroppableAsTargetToResourceMove } from '@/src/modules/resources/hooks/useDroppableAsTargetToResourceMove';
import useUIStore from '@/src/store/ui';

import { useMutationCreateSubFolder } from '@/src/modules/resources/mutations/useMutationCreateSubFolder';
import { useQueryFolders } from '@/src/modules/resources/queries/useQueryFolders';
import { useMutationCreateNewSpace } from '@/src/modules/spaces/mutations/useMutationCreateNewSpace';
import { Flex } from '@/src/modules/ui/components/Flex';
import { ButtonExpandCollapse } from '@/src/modules/ui/components/button/ButtonExpandCollapse';
import { mediaHover, mediaMobile } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { preventForwardPropsConfig } from '@/src/modules/ui/utils/preventForwardProps';
import Tooltip from '@/src/ui/Tooltip';
import { PlusIcon } from '@radix-ui/react-icons';
import { cubicBezier, motion, useWillChange } from 'framer-motion';
import styled, { css } from 'styled-components';
import { shallow } from 'zustand/shallow';
import { useTreeView } from '.';
import Spinner from '../../Spinner/Spinner';
import { FolderIcon } from '../icons';
import { TreeItem, TreeNode } from './types';

interface NodeItemProps {
  isActive?: boolean;
}

const Node: React.FC<TreeNode & { parent?: { id: string; name: string } }> = ({ item, data }) => {
  const router = useRouter();
  const { isMobileView } = useResponsive();
  const { openNodes, setOpenNodes, focusedId, setFocusedId, selectedId, setSelectedId } =
    useTreeView();
  const willChange = useWillChange();

  const droppableRef = React.useRef<HTMLDivElement | null>(null);

  const { styles: droppableStyles, droppableProps } = useDroppableAsTargetToResourceMove(
    droppableRef,
    item.canAcceptMovedItems
      ? {
          id: item.id,
          name: item.name,
        }
      : undefined,
    {
      action: 'drop-into-sidebar',
    },
  );

  const { sidebarExpanded } = useUIStore((state) => pick(state, ['sidebarExpanded']), shallow);
  const [preloadChildren, setPreloadChildren] = useState(false);
  const isOpen = openNodes.includes(item.id) && !isMobileView;

  const renderChildren = isOpen || preloadChildren;

  const foldersFetchingEnabled = Boolean(renderChildren && item.isFolder && sidebarExpanded);

  const { folders: folders, isLoading: isLoadingFolders } = useQueryFolders(item.id, {
    enabled: foldersFetchingEnabled,
  });

  const loading = item.loading || isLoadingFolders;
  const isActive = selectedId === item.id;

  // Revert preloadChildren after 1 minute if isOpen=false && preloadChildren=true
  // This allows minimizing revalidation of child folders that are no longer visible
  useEffect(() => {
    if (isOpen || !preloadChildren) return;

    const timeout = window.setTimeout(() => {
      setPreloadChildren(false);
    }, 60000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen, preloadChildren]);

  const ref = useRef<HTMLLIElement>(null);
  const ulRef = useRef<HTMLUListElement>(null);

  const isSubfolderOpen = useCallback((id: string) => openNodes.includes(id), [openNodes]);

  const subfolders = folders.map((folder) => ({
    id: folder.id,
    name: folder.name || 'Untitled folder',
    navigateTo: `/folders/${folder.id}`,
    newItemActions: true,
    newItemActionId: 'subfolders',
    isFolder: true,
    /**
     * if cannot accept moved items, then subfolders can't either
     */
    canAcceptMovedItems: item.canAcceptMovedItems,
    icon: (
      <FolderIcon
        style={{ cursor: 'pointer', opacity: 0.75 }}
        width="100%"
        height="16px"
        isOpen={isSubfolderOpen(folder.id) || folder.id === selectedId}
      />
    ),
  }));

  const handleKeyDown = (e: React.KeyboardEvent<HTMLLIElement>) => {
    const currentElement = e.currentTarget;
    chainShortcutHandlers(
      e,
      [
        /**
         * set item as selected
         */
        (event) => {
          if (['Enter', ' '].includes(event.key)) {
            setSelectedId(item.id);
            item.navigateTo &&
              router.push(item.navigateTo, undefined, {
                scroll: false,
              });
            return true;
          }
          return false;
        },
        /**
         * move up
         */
        (event) => {
          if (event.key === 'ArrowUp') {
            let previousElement = currentElement.previousElementSibling;
            // Check if there are open children of the previous siblings which needs to be skipped.
            while (previousElement && previousElement.getAttribute('aria-expanded') === 'true') {
              const children = previousElement.querySelectorAll(
                ':scope > ul > li[role="treeitem"]',
              );

              if (children.length || subfolders?.length) {
                const mergedList = [...(children || []), ...(subfolders || [])];
                previousElement = children[mergedList.length - 1]; // last child of the previous open parent
              } else {
                break;
              }
            }

            // If there are no more siblings, move to the parent.
            if (!previousElement) {
              const parentElement = currentElement?.parentElement?.closest(
                'li[role="treeitem"]',
              ) as HTMLLIElement;

              if (parentElement) {
                setFocusedId(parentElement.id);
              }
            } else if (previousElement) {
              setFocusedId(previousElement.id);
            }
            return true;
          }
          return false;
        },
        /**
         * move down
         */
        (event) => {
          if (event.key === 'ArrowDown') {
            // If current element is expanded, first child must be selected.
            if (currentElement.getAttribute('aria-expanded') === 'true') {
              const firstChild = currentElement.querySelector('li[role="treeitem"]');
              if (firstChild) {
                setFocusedId(firstChild.id);
              }
            } else {
              // Else, get next sibling or closest uncle/aunt.
              let nextElement = currentElement.nextElementSibling;

              // Check if current element has uncles/aunts (if currentElement is the last child)
              if (!nextElement) {
                let parentElement = currentElement?.parentElement?.closest('li[role="treeitem"]');
                while (!nextElement && parentElement) {
                  nextElement = parentElement.nextElementSibling;
                  parentElement = parentElement.parentElement?.closest('li[role="treeitem"]');
                }
              }
              if (nextElement) {
                setFocusedId(nextElement.id);
              }
            }
            return true;
          }
          return false;
        },
        /**
         * arrow right
         */
        (event) => {
          if (event.key === 'ArrowRight') {
            if ((!item.children || !item.children.length) && (!subfolders || !subfolders.length)) {
              return false;
            }

            setOpenNodes([...openNodes, item.id]);

            // Focuses on the first child if open
            if (
              currentElement &&
              ((item.children && item.children.length) || (subfolders && subfolders.length)) &&
              isOpen
            ) {
              const firstChild = currentElement.querySelector('li:first-of-type') as HTMLLIElement;

              if (firstChild) {
                setFocusedId(firstChild.id);
                return true;
              }
            }
          }
          return false;
        },
        (event) => {
          if (event.key === 'ArrowLeft') {
            setOpenNodes(openNodes.filter((node: string) => node !== item.id));
            return true;
          }
          return false;
        },
        (event) => {
          if (event.key === 'Tabs') {
            if (focusedId) {
              setFocusedId(null);
            } else {
              setFocusedId(item.id);
            }
            return true;
          }
          return false;
        },
        /**
         * matching key - search
         */
        (event) => {
          if (/[a-zA-Z]/.test(event.key)) {
            // checks if e.key is a letter between a-z or A-Z
            const firstAvailableItem = findNode(data, e.key.toLowerCase());

            if (firstAvailableItem) {
              setFocusedId(firstAvailableItem.id);
              setSelectedId(firstAvailableItem.id);

              const parentPath = findParents(data, firstAvailableItem.id);
              if (parentPath !== null) {
                setOpenNodes((prev: string[]) => [...prev, ...parentPath]);
              }
              return true;
            }
          }
          return false;
        },
      ],
      {
        stopPropagationOnHandle: true,
        preventDefaultOnHandle: true,
        stopOnFirstSuccess: true,
      },
    );
  };

  useEffect(() => {
    if (focusedId === item.id && ref.current) {
      ref.current.focus();
    }
  }, [focusedId, item.id]);

  useEffect(() => {
    const isHome = router.pathname === '/';
    const isTimeline = router.pathname === '/timeline';
    const isSpaces = router.pathname === '/spaces';
    const isConnections = router.pathname === '/connections';
    const isSpace = router.query?.listId;
    const isFolder = router.query?.folderId;

    switch (item.id) {
      case 'home':
        if (isHome) setSelectedId(item.id);
        break;
      case 'timeline':
        if (isTimeline) setSelectedId(item.id);
        break;
      case 'spaces':
        if (isSpaces) setSelectedId(item.id);
        break;
      case 'connections':
        if (isConnections) setSelectedId(item.id);
        break;

      default:
        break;
    }

    if (isSpace) {
      setSelectedId(isSpace as string);
    }

    if (isFolder) {
      setSelectedId(isFolder as string);
    }
  }, [item.id, router.pathname, router.query?.folderId, router.query?.listId, setSelectedId]);

  const liConditionalProps = {
    ...(item.children &&
      item.children.length > 0 && {
        ['aria-expanded']: isOpen,
      }),
    ...(subfolders &&
      subfolders.length > 0 && {
        ['aria-expanded']: isOpen,
      }),
  };

  const { limitModal, ...mutationCreateSpace } = useMutationCreateNewSpace();

  const handleCreateNewList = () => {
    mutationCreateSpace.mutate(
      {
        action: 'sidebar',
      },
      {
        onSuccess: (space) => {
          router.push(`/spaces/${space.id}`, undefined, { scroll: false });
          setSelectedId(space.id);
        },
      },
    );
  };

  const { mutate: mutateCreateSubFolder } = useMutationCreateSubFolder();

  const handleCreateSubFolder = () => {
    mutateCreateSubFolder({
      parent: {
        id: item.id,
        name: item.name,
      },
      action: 'sidebar',
    });
  };

  const newItemActions: {
    [key: string]: {
      action: () => void;
      label: string;
    };
  } = {
    spaces: {
      action: handleCreateNewList,
      label: 'Create new space',
    },
    subfolders: {
      action: handleCreateSubFolder,
      label: 'Create subfolder',
    },
    connections: {
      action: () => router.push('/add-connection', undefined, { scroll: false }),
      label: 'Add new connection',
    },
  };

  const mergedList = useMemo(() => {
    return [...(item?.children || []), ...(subfolders || [])];
  }, [item.children, subfolders]);

  return (
    <>
      {limitModal}
      <li
        key={item.id}
        ref={ref}
        id={item.id}
        role="treeitem"
        aria-selected={selectedId == item.id}
        aria-label={item.name}
        onKeyDown={handleKeyDown}
        className={clsx(
          'group w-full rounded-[6px] transition-colors outline-none first:mt-[4px] cursor-pointer max-w-full min-w-0',
        )}
        {...liConditionalProps}
      >
        <ItemInner
          canAcceptMovedItems={item.canAcceptMovedItems}
          ref={droppableRef}
          {...droppableProps}
          className={clsx(
            'relative w-full flex items-center py-1 px-2 rounded-[6px] transition-[background,border,gap] outline-none border-solid border-[1px] border-transparent',
            {
              'group-focus-visible:border-[#7c838a] overflow-clip': item.id === focusedId,
            },
          )}
          style={droppableStyles}
          data-testid={item.testId ?? `sidebar-${item.id}-item`}
          onClick={() => {
            item.navigateTo &&
              router.push(item.navigateTo, undefined, {
                scroll: false,
              });
            setSelectedId(item.id);
          }}
        >
          {!sidebarExpanded && !isMobileView && item.icon ? (
            <Tooltip label={item.name} delay={1000} placement="right">
              <NodeItemIconWrapper isActive={isActive}>{item.icon}</NodeItemIconWrapper>
            </Tooltip>
          ) : (
            <NodeItemIconWrapper isActive={isActive}>{item.icon && item.icon}</NodeItemIconWrapper>
          )}

          <ItemContent
            className={clsx(
              'shrink truncate transition-[opacity,transform] ease-[cubic-bezier(.075,.82,.165,1)] ml-[10px]',
            )}
          >
            {item.name}
          </ItemContent>
          {(item.newItemActions || mergedList.length > 0) && !isMobileView && sidebarExpanded ? (
            <>
              <ButtonExpandCollapse
                className={clsx('ml-auto group/icon ', sidebarExpanded ? 'opacity-1' : 'opacity-0')}
                onPointerOver={() => setPreloadChildren(true)}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpenNodes(
                    isOpen
                      ? openNodes.filter((node: string) => node !== item.id)
                      : [...openNodes, item.id],
                  );
                }}
                disabled={isLoadingFolders && isOpen}
              >
                {loading && isOpen && <Spinner size={8} color="gray" />}

                {(!loading || !isOpen) && (
                  <ChevronRightThinIcon
                    className={clsx(
                      'w-3 h-3 transition-transform ease-[cubic-bezier(.075,.82,.165,1)]',
                      !isOpen ? 'transform rotate-90' : 'transform -rotate-90',
                    )}
                  />
                )}
              </ButtonExpandCollapse>
            </>
          ) : null}
        </ItemInner>

        {(mergedList && mergedList.length > 0) || (item.newItemActions && item.newItemActionId) ? (
          <motion.ul
            key={item.id}
            ref={ulRef}
            role="group"
            initial={isOpen && sidebarExpanded ? 'open' : 'closed'}
            variants={{
              open: {
                height: 'auto',
                transition: {
                  delay: 0.1,
                },
              },
              closed: {
                height: 0,
              },
            }}
            animate={isOpen && sidebarExpanded ? 'open' : 'closed'}
            transition={{
              duration: 0.2,
              ease: cubicBezier(0.075, 0.82, 0.165, 1),
            }}
            className={clsx(
              'overflow-hidden pl-6 ease-[cubic-bezier(.075,.82,.165,1)] space-y-[4px]',
            )}
            style={{
              willChange,
            }}
          >
            {renderChildren && (
              <>
                {mergedList.map((child: TreeItem) => (
                  <Node
                    key={child.id}
                    parent={{
                      id: item.id,
                      name: item.name,
                    }}
                    item={child}
                    data={data}
                  />
                ))}

                {item.newItemActions && item?.newItemActionId ? (
                  <li
                    id={item.id}
                    role="treeitem"
                    aria-selected={selectedId == item.id}
                    aria-label={item.name}
                    className={clsx(
                      'w-full rounded-[6px] transition-colors outline-none first:mt-[4px] cursor-pointer',
                    )}
                    {...liConditionalProps}
                  >
                    <NewActionButton
                      onClick={newItemActions[item?.newItemActionId]?.action}
                      className={clsx(
                        'relative w-full flex items-center h-[40px] py-1 px-2 rounded-[6px] outline-none border-solid border-[1px] border-transparent transition-colors ease-[cubic-bezier(.075,.82,.165,1)]font-medium',
                      )}
                    >
                      <span className={clsx('shrink-0 flex justify-center w-[22px]')}>
                        <PlusIcon
                          style={{
                            width: 14,
                            height: 'auto',
                            fontWeight: 500,
                          }}
                        />
                      </span>

                      <span className={clsx('truncate md:text-sm ml-[10px]')}>
                        {newItemActions[item?.newItemActionId]?.label}
                      </span>
                    </NewActionButton>
                  </li>
                ) : null}
              </>
            )}
          </motion.ul>
        ) : null}
      </li>
    </>
  );
};

export default memo(Node);

const cssDisabledWhenDraggingItems = css`
  body[data-drag-select-active='true'] & {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const ItemInner = styled.div<{ canAcceptMovedItems?: boolean }>`
  ${(p) => !p.canAcceptMovedItems && cssDisabledWhenDraggingItems}

  height: 40px;
  overflow: hidden;
  ${mediaHover} {
    &:hover {
      background: ${cssVar['color-bg-tertiary']};
    }
  }

  ${mediaMobile} {
    height: 48px;
  }
`;

const ItemContent = styled.span.withConfig(preventForwardPropsConfig(['isActive']))<NodeItemProps>`
  color: ${(p) => (p.isActive ? cssVar['color-app-primary'] : cssVar['color-text-quaternary'])};
  transition: color 0.2s;
  font-size: 0.875rem;
  font-weight: 500;
  overflow: hidden;
  ${mediaMobile} {
    font-size: 1.125rem;
  }
`;

const NodeItemIconWrapper = styled(Flex)
  .attrs<NodeItemProps>((props) => {
    return {
      justifyContent: 'center',
      alignItems: 'center',
      ...props,
    };
  })
  .withConfig(preventForwardPropsConfig(['isActive']))`
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  color: ${(p) =>
    p.isActive ? cssVar['color-app-primary-text-whiteOnDark'] : cssVar['color-text-quaternary']};
`;

const NewActionButton = styled.button`
  color: ${cssVar['color-text-quaternary']};
  ${cssDisabledWhenDraggingItems};
  ${mediaHover} {
    &:hover {
      background: rgba(${cssVar['color-app-primary-rgb']}, 0.05);
      color: ${cssVar['color-app-primary']};
    }
  }
`;

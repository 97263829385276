import AssistantButton from '@/src/components/AssistantButton/AssistantButton';
import { StandaloneChat } from '@/src/modules/assistant/components/StandaloneChat';
import { useAssistantStore } from '@/src/modules/assistant/stores/assistantStore';

export const Assistant = () => {
  const { setChatAssistantOpen } = useAssistantStore();

  const onClickOpenChatbot = () => {
    setChatAssistantOpen(true);
  };

  return (
    <>
      <AssistantButton onClick={onClickOpenChatbot} />
      <StandaloneChat />
    </>
  );
};

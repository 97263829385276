import CloseIcon from '@/src/icons/CloseIcon';
import { pick } from '@/src/lib/store';
import { Header } from '@/src/modules/resources/components/NewResource/components/Header';
import {
  TextAreaContainer,
  TextAreaInput,
} from '@/src/modules/resources/components/NewResource/components/TextArea';
import { NewResourceInputContainer } from '@/src/modules/resources/components/NewResource/components/TextInputContainer';
import useUIStore from '@/src/store/ui';
import { useFormik } from 'formik';
import React from 'react';
import { shallow } from 'zustand/shallow';

import useDebounce from '@/src/hooks/debounce';
import { MagicSuggestions } from '@/src/modules/magic/components/MagicSuggestions';
import { MagicSuggestionsContextProvider } from '@/src/modules/magic/components/MagicSuggestionsContext';
import { SuggestionContext } from '@/src/modules/magic/magic.types';
import { useQueryLinkPreview } from '@/src/modules/resource-detail/queries/useQueryLinkPreview';
import { SubmitFooter } from '@/src/modules/resources/components/NewResource/components/SubmitFooter';
import {
  NewResourceType,
  NewResourceTypesConfig,
} from '@/src/modules/resources/components/NewResource/config';
import { useNewResourceContext } from '@/src/modules/resources/components/NewResource/context/ModalNewResourceContext';
import { useMutationCreateResourceV2 } from '@/src/modules/resources/mutations/useMutationCreateResourceV2';
import { InputField } from '@/src/modules/ui/components/Field';
import { Flex } from '@/src/modules/ui/components/Flex';
import { Spinner } from '@/src/modules/ui/components/Spinner';
import { ClearInputButton } from '@/src/modules/ui/components/TextInput/TextInput';
import { titleFromUrl } from '@/src/utils/formatters';
import { prettifyForLLM } from '@/src/utils/text';
import { formatUrlWithProtocol } from '@/src/utils/url';
import isHotkey from 'is-hotkey';
import * as yup from 'yup';

const MAX_COMMENT_LENGTH = 25000;

const validationSchema = yup.object({
  url: yup
    .string()
    .matches(
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
      'Invalid URL',
    )
    .required('URL is required'),
  comment: yup.string().max(MAX_COMMENT_LENGTH, 'Comment is too long'),
});

export const ModalContentLink: React.FC = () => {
  const { newModalOptions } = useUIStore((s) => pick(s, ['newModalOptions']), shallow);

  const { url, shared, comment } = newModalOptions;
  const { destinationParent, selectedTags, handleClose, onSelectSuggestion } =
    useNewResourceContext();

  const { mutateAsync: mutateCreateResource, isPending } = useMutationCreateResourceV2();

  const formik = useFormik<{
    url: string;
    comment: string;
  }>({
    initialValues: {
      url: url || '',
      comment: comment || '',
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async ({ url, comment }, formik) => {
      const formattedUrl = formatUrlWithProtocol(url!);

      handleClose(
        mutateCreateResource({
          data: {
            createType: 'bookmark',
            body: {
              metadata: {
                linkPreview: linkPreview as any,
              },
              parentId: destinationParent!.id,
              url: formattedUrl,
              name:
                (linkPreview && (linkPreview.title || linkPreview.siteName)) || titleFromUrl(url!),
              tags: selectedTags.map((tag) => (tag.isDraft ? { name: tag.name } : { id: tag.id })),
              comment: comment
                ? {
                    content: comment,
                  }
                : undefined,
            },
            optimisticData: {
              tags: selectedTags,
              parent: destinationParent!,
              linkPreview,
            },
          },
        }).finally(() => formik.setSubmitting(false)),
      );
    },
  });

  const debouncedUrl = useDebounce(formik.values.url, 300);

  const { data: linkPreview, isFetching: isLoadingLinkPreview } = useQueryLinkPreview(debouncedUrl);

  const titleOptions = NewResourceTypesConfig[NewResourceType.Link];

  const inputRef = React.useRef<HTMLInputElement>(null);
  const commentRef = React.useRef<HTMLTextAreaElement>(null);

  const suggestionsContext: SuggestionContext | undefined =
    !formik.errors.url && formik.values.url && !isLoadingLinkPreview
      ? {
          name: linkPreview?.title ?? undefined,
          url: formik.values.url,
          comment: formik.values.comment,
          otherInfo: prettifyForLLM({
            url: linkPreview?.url,
            title: linkPreview?.title,
            description: linkPreview?.description,
            siteName: linkPreview?.siteName,
          }),
        }
      : undefined;

  return (
    <MagicSuggestionsContextProvider
      onSelectSuggestion={onSelectSuggestion}
      context={suggestionsContext}
      includeFolders
    >
      <Flex
        flexGrow
        gap={24}
        direction="column"
        style={{ padding: '1rem' }}
        as="form"
        onSubmit={formik.handleSubmit}
      >
        <Header
          submitLoading={isPending}
          icon={<titleOptions.icon style={titleOptions.iconStyle} />}
          label={titleOptions.label}
          minLabel={titleOptions.minLabel}
        />
        <Flex direction="column" gap="elementsContainer" flexGrow>
          <InputField gap="sm">
            <NewResourceInputContainer>
              <input
                name="url"
                placeholder="Type or paste a link..."
                value={formik.values.url}
                onChange={formik.handleChange}
                onKeyDown={(e) => {
                  if (isHotkey('Enter', e)) {
                    e.preventDefault();
                    commentRef.current?.focus();
                  }
                }}
                data-testid="url-input"
                autoFocus={!shared}
                type="url"
                ref={inputRef}
              />
              {isLoadingLinkPreview && !linkPreview && <Spinner size={16} />}
              {(formik.values.url?.length ?? 0) > 0 && (
                <ClearInputButton
                  tabIndex={-1}
                  onClick={() => {
                    formik.setFieldValue('url', '');
                    inputRef.current?.focus();
                  }}
                >
                  <CloseIcon />
                </ClearInputButton>
              )}
            </NewResourceInputContainer>
          </InputField>
          <InputField gap="sm" style={{ flexGrow: 1 }}>
            <TextAreaContainer>
              <TextAreaInput
                ref={commentRef}
                name="comment"
                value={formik.values.comment}
                onChange={formik.handleChange}
                placeholder="Say something about it... (optional)"
                /**
                 * autofocus on mount if URL is provided in the new modal state
                 * this is the pasted url
                 */
                autoFocus={!!url}
              />
              <MagicSuggestions
                style={{
                  paddingBottom: 13,
                }}
              />
            </TextAreaContainer>
            <InputField.ErrorMessage message={formik.errors.comment} />
          </InputField>
        </Flex>
        <SubmitFooter
          submitDisabled={
            !formik.values.url ||
            formik.isSubmitting ||
            !formik.isValid ||
            isPending ||
            !destinationParent?.id
          }
          submitLoading={isPending}
          onSubmit={formik.handleSubmit}
        />
      </Flex>
    </MagicSuggestionsContextProvider>
  );
};
